class Helper {
    
    static getOrdinalSuffix = (day) => {
      const j = day % 10,
        k = day % 100;
      if (j === 1 && k !== 11) {
        return day + 'st';
      }
      if (j === 2 && k !== 12) {
        return day + 'nd';
      }
      if (j === 3 && k !== 13) {
        return day + 'rd';
      }
      return day + 'th';
    };
    static formatDate = (dateSlug) => {
      if (!dateSlug) {
        return '';
      }
      const [day, month, year] = dateSlug.split('-');
      const date = new Date(year, month - 1, day);
      const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
      const formattedDay = date.getDate();
      return `${date.toLocaleDateString('en-US', options).replace(formattedDay, Helper.getOrdinalSuffix(formattedDay))}`;
    };
    static formatTime = (time) => {
      if(time){
        const [hours, minutes] = time.split(':');
        let formattedTime = '';
        let period = 'AM';
    
        if (parseInt(hours) >= 12) {
          period = 'PM';
          if (parseInt(hours) > 12) {
            formattedTime = `${parseInt(hours) - 12}:${minutes}`;
          } else {
            formattedTime = `${hours}:${minutes}`;
          }
        } else if(parseInt(hours) == 0) {
          formattedTime = `12:${minutes}`;
        } else {
          formattedTime = `${hours}:${minutes}`;
        }
        return formattedTime + ' ' + period;
      }else{
        return time;
      }
      
    };
    /* static calculateHourDifference = (startTime, endTime) => {
      const [startHours, startMinutes] = startTime.split(':');
      const [endHours, endMinutes] = endTime.split(':');
    
      const startDate = new Date();
      startDate.setHours(startHours);
      startDate.setMinutes(startMinutes);
    
      const endDate = new Date();
      endDate.setHours(endHours);
      endDate.setMinutes(endMinutes);
    
      const timeDiff = Math.abs(endDate - startDate);
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    
      return `${hours} : ${minutes}`;
    }; */
    /* static calculateHourDifference = (startTime, endTime) => {
      const [startHours, startMinutes, startSeconds] = startTime.split(':');
      const [endHours, endMinutes, endSeconds] = endTime.split(':');

      const startDate = new Date();
      startDate.setHours(startHours);
      startDate.setMinutes(startMinutes);
      startDate.setSeconds(startSeconds);

      const endDate = new Date();
      endDate.setHours(endHours);
      endDate.setMinutes(endMinutes);
      endDate.setSeconds(endSeconds);

      // If end time is earlier in the day than start time, add one day to end time
      if (endDate <= startDate) {
          endDate.setDate(endDate.getDate() + 1);
      }

      const timeDiff = Math.abs(endDate - startDate);
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);

      return `${hours} : ${minutes}`;
    }; */
    static calculateHourDifference = (startTime, endTime) => {
      const [startHours, startMinutes] = startTime.split(':');
      const [endHours, endMinutes] = endTime.split(':');
  
      const startDate = new Date();
      startDate.setHours(startHours);
      startDate.setMinutes(startMinutes);
      startDate.setSeconds(0);
  
      const endDate = new Date();
      endDate.setHours(endHours);
      endDate.setMinutes(endMinutes);
      endDate.setSeconds(0);
  
      // If end time is earlier in the day than start time, add one day to end time
      if (endDate <= startDate) {
        endDate.setDate(endDate.getDate() + 1);
      }
  
      const timeDiff = Math.abs(endDate - startDate);
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
  
      return `${hours} : ${minutes}`;
    };
    static calculateHourDifferenceForApi = (startTime, endTime) => {
      if (!startTime || !endTime) {
        return 'Invalid time format'; // Add appropriate error handling
      }
    
      const [startHours, startMinutes, startPeriod] = startTime.split(/:|\s/); // Split by colon and space
      const [endHours, endMinutes, endPeriod] = endTime.split(/:|\s/); // Split by colon and space
    
      let startHour = parseInt(startHours, 10);
      let endHour = parseInt(endHours, 10);
    
      // Adjust hours based on AM or PM
      if (startPeriod === 'PM' && startHour !== 12) {
        startHour += 12;
      }
      if (endPeriod === 'PM' && endHour !== 12) {
        endHour += 12;
      }
      if (startPeriod === 'AM' && startHour === 12) {
        startHour = 0;
      }
      if (endPeriod === 'AM' && endHour === 12) {
        endHour = 0;
      }
    
      const startDate = new Date();
      startDate.setHours(startHour, parseInt(startMinutes, 10));
    
      const endDate = new Date();
      endDate.setHours(endHour, parseInt(endMinutes, 10));
    
      const timeDiff = Math.abs(endDate - startDate);
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    
      return `${hours} : ${minutes}`;
    };
    static formatTimeFromMinutes = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
    }
    static formatAsIndianRupee = (amount) => {
      if (!amount) {
        return '';
      }
      return amount.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
      });
    };
    
}
  
export default Helper;